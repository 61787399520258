<template>
  <!-- The Modal -->
  <div
    class="simple-modal d-flex"
    @click="outsideClick"
    :style="{ visibility: visible ? 'visible' : 'hidden' }"
  >
    <!-- Modal content -->
    <div
      class="simple-modal-content p-4 mx-auto my-auto"
      :style="modalStyle"
      @click.stop
    >
      <!-- Close button -->
      <span class="close" @click="$emit('close')">&times;</span>

      <!-- Heading -->
      <h1 v-if="heading" class="hty-heading text-center mb-2">
        {{ heading }}
      </h1>

      <!-- Loader -->
      <div
        v-if="loading"
        class="d-flex h-100 align-items-center justify-content-center"
      >
        <img :src="require('@/assets/svg/spinner_black.svg')" />
      </div>

      <!-- Slot -->
      <slot v-else></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modalStyle: {
      type: [Object, String],
      required: false,
    },
    outsideClickClose: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    outsideClick() {
      this.$emit('outside-click');

      if (this.outsideClickClose) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* The Modal (background) */
.simple-modal {
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.simple-modal-content {
  border-radius: 10px;
  background-color: #fdfdfd;
  margin: auto;
  padding: 20px;
  display: inline-block;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  position: relative;
  top: -15px;
  right: -10px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
