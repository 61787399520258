<template>
  <SimpleModal
    :heading="$t('socialProjects.donate')"
    :visible="!paymentModal"
    @close="$emit('close')"
    modal-style="width: 550px"
  >
    <PaymentModal
      v-if="paymentModal"
      :amount="amount"
      :round-costs-option="false"
      :success-text="$t('socialProjects.paymentSuccessText')"
      @close="paymentModal = false"
      @success-close="
        paymetnModal = false;
        $emit('close');
      "
      @failed-close="paymentModal = false"
    ></PaymentModal>

    <div>
      <!-- Image -->
      <div>
        <img
          :src="item.images[0]"
          style="
            border-radius: 5px;
            object-fit: cover;
            width: 100%;
            height: 200px;
          "
        />
      </div>

      <!-- Title -->
      <h2 class="hty-heading-2 text-center my-2">
        {{ item.title }}
      </h2>

      <!-- Description -->
      <p v-html="item.description" class="description"></p>

      <!-- Label -->
      <label class="d-block label mt-3">
        {{ $t('socialProjects.howMuchDonate') }}
      </label>

      <!-- Inputs -->
      <div class="form-row">
        <div class="col-12 col-sm-4">
          <InputElement
            v-model="amount"
            type="number"
            validation-rules="required"
            validation-name="text"
            :placeholder="$t('socialProjects.enterAmount')"
          />
        </div>
        <div class="col-12 col-sm mt-2 mt-sm-0">
          <ButtonElement @click="amount = 10" block grey-secondary>
            {{ 10 | EUR }}
          </ButtonElement>
        </div>
        <div class="col-12 col-sm mt-2 mt-sm-0">
          <ButtonElement @click="amount = 50" block grey-secondary>
            {{ 50 | EUR }}
          </ButtonElement>
        </div>
        <div class="col-12 col-sm mt-2 mt-sm-0">
          <ButtonElement @click="amount = 100" block grey-secondary>
            {{ 100 | EUR }}
          </ButtonElement>
        </div>
      </div>

      <!-- Buttons -->
      <div class="row mt-4">
        <div class="col-12 col-sm-6">
          <ButtonElement orange-secondary block @click="$emit('close')">
            {{ $t('socialProjects.cancel') }}
          </ButtonElement>
        </div>
        <div class="col mt-2 mt-sm-0">
          <ButtonElement
            orange
            block
            @click="paymentModal = true"
            :disabled="!amount"
          >
            {{ $t('socialProjects.continue') }}
          </ButtonElement>
        </div>
      </div>
    </div>
  </SimpleModal>
</template>

<script>
import PaymentModal from '@/components/Payment/PaymentModal';
import SimpleModal from '@/components/Modal/SimpleModal';

export default {
  props: {
    socialProject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amount: '',
      item: {},
      paymentModal: false,
    };
  },
  created() {
    this.item = this.socialProject;
  },
  components: {
    PaymentModal,
    SimpleModal,
  },
};
</script>

<style lang="scss" scoped>
.description {
  color: #999999;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
