<template>
  <SimpleModal
    :heading="step == 0 ? $t('payment.chooseMethod') : null"
    @close="$emit('close')"
    :modal-style="{ width: step == 0 ? '800px' : '500px' }"
  >
    <!-- Success -->
    <div v-if="step == 1" class="text-center">
      <div>
        <img :src="require('@/assets/svg/payment/success.svg')" />
      </div>
      <div class="text-orange font-weight-bold mt-4" style="font-size: 30px">
        {{ amount | EUR }}
      </div>
      <div class="text-secondary font-weight-bold mt-1">
        {{ $t('payment.paid') }}
      </div>
      <div class="hty-heading mt-4">
        {{ $t('payment.paymentSuccess') }}
      </div>
      <div
        class="mt-2 font-weight-bold"
        style="color: #2b2b2b; font-size: 20px"
      >
        {{ successText }}
      </div>
      <ButtonElement
        orange
        @click="$emit('success-close')"
        class="mx-auto px-5 mt-4"
      >
        {{ $t('buttons.close') }}
      </ButtonElement>
    </div>

    <!-- Failure -->
    <div v-if="step == -1" class="text-center">
      <div>
        <img :src="require('@/assets/svg/payment/failed.svg')" />
      </div>
      <div class="hty-heading mt-4">
        {{ $t('payment.paymentFailed') }}
      </div>
      <div
        class="mt-4 font-weight-bold"
        style="color: #2b2b2b; font-size: 20px"
      >
        {{ $t('payment.tryAgain') }}
      </div>
      <div class="row mx-auto mt-4" style="max-width: 400px">
        <div class="col">
          <ButtonElement orange-secondary block @click="$emit('failed-close')">
            {{ $t('buttons.cancel') }}
          </ButtonElement>
        </div>
        <div class="col">
          <ButtonElement orange block @click="step = 0">
            {{ $t('buttons.tryAgain') }}
          </ButtonElement>
        </div>
      </div>
    </div>

    <!-- Payment form -->
    <div v-if="step == 0" class="px-md-5">
      <!-- Total amount & note -->
      <b class="d-block mb-4">
        <span class="d-inline text-orange mr-3">
          {{ $t('payment.totalAmount') }}
        </span>
        <span class="d-inline mr-2">{{ amount | EUR }}</span>
        <span class="text-secondary">{{ note }}</span>
      </b>

      <!-- Round costs checkbox -->
      <div v-if="roundCostsOption" class="mb-4">
        <CheckboxElement
          :value="false"
          v-model="formData.roundCosts"
          :text="$t('payment.roundCostsText')"
        />
      </div>

      <!-- Credit card - Selection -->
      <div class="row">
        <div class="col-auto">
          <RadioElement
            radio-value="credit_card"
            name="paymentType"
            v-model="formData.paymentType"
            :text="$t('payment.creditOrDebitCard')"
          />
        </div>
        <div class="card-brands col text-right">
          <img :src="require('@/assets/svg/payment/visa.svg')" />
          <img :src="require('@/assets/svg/payment/americanexpress.svg')" />
          <img :src="require('@/assets/svg/payment/mastercard.svg')" />
          <img :src="require('@/assets/svg/payment/maestrocard.svg')" />
        </div>
      </div>

      <!-- Credit card - Secupay iframe -->
      <div v-show="formData.paymentType == 'credit_card'" class="mb-4">
        <div style="max-width: 700px">
          <iframe
            id="iframe"
            src="https://cc.secupay.com"
            width="100%"
            height="230px"
            style="border: none"
          ></iframe>
        </div>
        <form>
          <ButtonElement id="submit-button" type="button" orange>
            {{ $t('buttons.confirm') }}
          </ButtonElement>
        </form>
      </div>

      <!-- Paypal connect -->
      <div class="mb-4 mt-3">
        <RadioElement
          radio-value="paypal"
          name="paymentType"
          v-model="formData.paymentType"
          text="Connect with paypal"
        />

        <div class="row">
          <div class="col">
            <img :src="require('@/assets/svg/payment/paypal.svg')" />
          </div>
          <div class="col">
            <ButtonElement type="button" block orange-secondary>
              {{ $t('buttons.connect') }}
            </ButtonElement>
          </div>
        </div>
      </div>

      <!-- Bank transfer connect -->
      <div class="mb-4">
        <RadioElement
          radio-value="bank_transfer"
          name="paymentType"
          v-model="formData.paymentType"
          :text="$t('payment.connectBankTransfer')"
        />

        <div class="row">
          <div class="col">
            <img :src="require('@/assets/svg/payment/bank_transfer.svg')" />
          </div>
          <div class="col">
            <ButtonElement type="button" block orange-secondary>
              {{ $t('buttons.connect') }}
            </ButtonElement>
          </div>
        </div>
      </div>

      <!-- Test - DELETE LATER -->
      <div class="text-right">
        <button @click="step = 1" class="mr-2">Test Success</button>
        <button @click="step = -1">Test Failure</button>
      </div>
    </div>
  </SimpleModal>
</template>

<script>
import SimpleModal from '@/components/Modal/SimpleModal';

export default {
  props: {
    amount: {
      type: [Number, String],
      required: false,
    },
    note: {
      type: String,
      required: false,
    },
    roundCostsOption: {
      type: Boolean,
      default: true,
    },
    successText: {
      type: String,
      default: 'Vielen Dank!',
    },
  },
  data() {
    return {
      formData: {
        paymentType: '',
        roundCosts: false,
      },
      step: 0, // -1 failed, 0 form, 1 success
    };
  },
  watch: {
    step(val) {
      switch (val) {
        case -1:
          this.$emit('failed');
          break;
        case 0:
          this.$emit('retry');
          break;
        case 1:
          this.$emit('success');
          break;
      }
    },
  },
  mounted() {
    this.loadScript();
  },
  methods: {
    loadScript() {
      const IFRAME_ORIGIN = 'https://cc.secupay.com';
      const iframeElement = document.getElementById('iframe');
      const iframeContentWindow = iframeElement.contentWindow;

      /**
       * Set iframe parameters
       */
      window.addEventListener(
        'load',
        function (e) {
          console.info('init CC iframe');

          // Set owner (optional)
          iframeContentWindow.postMessage(
            {
              action: 'set-owner',
              owner: 'Max Mustermann',
            },
            IFRAME_ORIGIN
          );

          // Set theme
          iframeContentWindow.postMessage(
            {
              action: 'set-theme',
              theme: 1, // 0 = secupay, 1 = custom
            },
            IFRAME_ORIGIN
          );

          // Set language (optional)
          iframeContentWindow.postMessage(
            {
              action: 'set-lang',
              lang: 'en',
            },
            IFRAME_ORIGIN
          );
        },
        false
      );

      /**
       * Respond on iframe messages
       */
      window.addEventListener(
        'message',
        function (e) {
          // Check origin
          if (e.origin !== IFRAME_ORIGIN) {
            console.warn('bad origin ' + e.origin);
            return;
          }

          switch (e.data.action) {
            // Iframe height changed. This is raised on load, and after validation.
            case 'cc-iframe-height':
              console.info('new iframe height ' + e.data.documentHeight);
              iframeElement.height = e.data.documentHeight + 'px';
              break;

            // Iframe sends data. These may be valid or not.
            case 'prepared-cc-data':
              console.info('credit card data', e.data.data);
              break;

            default:
              console.warn('unknown message ' + e.data.action, e.data);
              break;
          }
        },
        false
      );

      /**
       * Handle submit button
       */
      document
        .getElementById('submit-button')
        .addEventListener('click', function () {
          console.info('clicked button');

          // Make CC iframe validate the form, and send back the data
          iframeContentWindow.postMessage(
            { action: 'get-cc-data' },
            IFRAME_ORIGIN
          );
        });
    },
  },
  components: {
    SimpleModal,
  },
};
</script>

<style lang="scss" scoped>
.card-brands {
  img {
    margin-left: 10px;
  }
}

.radio-container {
  margin-bottom: 10px;
}
</style>
